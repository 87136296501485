import React from "react";
import { AttentionSeeker, Fade } from "react-awesome-reveal";
import { Jumbotron } from "./migration";

const Diseases = ({ title, description, diseases }) => {
  return (
    <Fade duration={2000}>
      <Jumbotron
        id="track_infectious_diseases"
        className="m-0 solutions-container"
      >
        <div className="container solutions-content ">
          <div className="solutions-info">
            <AttentionSeeker effect="bounce">
              <h2 className="display-4 mb-3 text-center">{title}</h2>
            </AttentionSeeker>
            <p className="text-center">{description}</p>
            <div className="solutions-items">
              {diseases.map((solution, index) => {
                return (
                  <div className="solutions-item" key={`${solution}_${index}`}>
                    <div className="solutions-item-icon"></div>
                    <div className="solutions-item-name">
                      <img
                        src={solution.animation}
                        alt="affectory_linkedin"
                        width={"42px"}
                        height={"32px"}
                      />
                      {solution.name}
                    </div>
                    <div className="solutions-item-description">
                      {solution.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/*
          <div className="team-animation">
            <DisplayLottie animationData={box} />
          </div> */}
        </div>
      </Jumbotron>
    </Fade>
  );
};

export default Diseases;
