import React from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { Jumbotron } from "./migration";

const POCTSolutionsBanner = ({
  heading,
  caption,
  emojis,
  solutions,
  colors,
}) => {
  const navigate = useNavigate();
  return (
    <Fade duration={2000}>
      <Jumbotron id="poctSolutions" className="m-0 poct-solutions-container">
        <div className="container poct-solutions-content ">
          <div className="poct-solutions-info">
            <h2 className="display-4 text-center">
              {heading}
              {emojis}
            </h2>
            <p className="text-center">{caption}</p>
          </div>
          <div className="poct-solutions-items">
            {solutions.map((solution, index) => {
              return (
                <div
                  className={`poct-solutions-item item${index + 1}`}
                  key={`${solution}_${index}`}
                >
                  <a className={`poct-solutions-item`} href={solution.link}>
                    <div className="poct-solutions-item-name">
                      {solution.name}
                    </div>
                    <div className="poct-solutions-item-description">
                      {solution.description}
                    </div>
                    <div className="poct-solutions-item-link">
                      Click here to find out more
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Jumbotron>
    </Fade>
  );
};

export default POCTSolutionsBanner;
