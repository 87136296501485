import React from "react";
import Typist from "react-typist-component";
import mainBodyAnimation from "../../assets/animations/main-body-animation.json";
import DisplayLottie from "../displayLottie/DisplayLottie";

const MainBody = React.forwardRef(
  ({ gradient, title, message, quote, subQuote, icons, hashtags }, ref) => {
    function chunkArray(array, chunkSize = 4) {
      // This function takes an array and a chunk size and splits the array into smaller arrays of the specified chunk size
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }

      return result;
    }
    // Split hashtags into groups of 3 or 8 depending on the window width
    const hashtagGroups = chunkArray(
      hashtags,
      window.innerWidth <= 968 ? 8 : 4
    );

    return (
      <div
        style={{
          background: `linear-gradient(136deg,${gradient})`,
          backgroundSize: "1200% 1200%",
        }}
        className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0 p-0"
      >
        <div id="stars"></div>
        <div className="main-body">
          <div className="main-body-section">
            <div className="main-body-section-contents">
              <div className="display-2">
                <div className="quote">
                  <h1>{quote}</h1>
                </div>
                <div className="subQuote">{subQuote}</div>
              </div>
              <div className="main-body-sub-section">
                <div className="message">
                  <Typist typingDelay={30}>
                    <div className="lead typist">{message}</div>
                  </Typist>
                </div>
              </div>
            </div>
            <div className="main-body-animation">
              <DisplayLottie animationData={mainBodyAnimation} />
            </div>
          </div>

          <div className="action">
            <div className="action-cta">
              <a
                className="btn btn-outline-light btn-lg "
                href="#poctSolutions"
                role="button"
                aria-label="Learn more about me"
              >
                Learn More!
              </a>
            </div>
            <div className="hashtags">
              {hashtagGroups.map((group, index) => (
                <div key={index} className="hashtags-group">
                  {group.map((hashtag, hashtagIndex) => (
                    <div key={hashtagIndex} className="hashtag-section">
                      {hashtag}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default MainBody;
