import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import animation from "../../assets/animations/mission.json";
import DisplayLottie from "../displayLottie/DisplayLottie";
import { Jumbotron } from "./migration";

const Mission = ({ heading, emoji, caption, message, link }) => {
  return (
    <Fade duration={2000}>
      <Jumbotron id="mission" className="m-0 mission-container">
        <div className="container mission-content ">
          <div className="mission-header">
            <h2 className="display-4 text-center">
              {heading}
              {emoji}
            </h2>
          </div>
          <div className="mission-info">
            <div className="mission-info-content">
              <Slide direction="left">
                <p className="text-left">{caption}</p>
                <p
                  className="text-center text-wrap d-none d-sm-block"
                  style={{ color: "#BD202F" }}
                >
                  <strong>
                    <Fade delay={500} cascade damping={0.02} triggerOnce>
                      {message}
                    </Fade>
                  </strong>
                </p>
                <p
                  className="d-block d-sm-none text-center"
                  style={{ color: "#BD202F" }}
                >
                  <strong>{message}</strong>
                </p>
              </Slide>
            </div>
            <div className="mission-animation">
              <Slide direction="right">
                <DisplayLottie animationData={animation} loop={true} />
              </Slide>
            </div>
          </div>
        </div>
      </Jumbotron>
    </Fade>
  );
};

export default Mission;
