import React from "react";
import { AttentionSeeker, Fade } from "react-awesome-reveal";
import { Jumbotron } from "./migration";

const Solutions = ({ title, emoji, description, solutions }) => {
  return (
    <Fade duration={2000}>
      <Jumbotron id="emergency_parameters" className="m-0 solutions-container">
        <div className="container solutions-content ">
          <div className="solutions-info">
            {/* <h2 className="display-4 mb-3 text-center">{emoji}</h2> */}
            <AttentionSeeker effect="bounce">
              <h2 className="display-4 mb-3 text-center">{title}</h2>
            </AttentionSeeker>
            <p className="text-center">{description}</p>
            <div className="solutions-items">
              {solutions.map((solution, index) => {
                return (
                  <div className="solutions-item" key={`${solution}_${index}`}>
                    <div className="solutions-item-photo"></div>
                    <div className="solutions-item-name">{solution.name}</div>
                    <div className="solutions-item-description">
                      {solution.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/*
          <div className="team-animation">
            <DisplayLottie animationData={box} />
          </div> */}
        </div>
      </Jumbotron>
    </Fade>
  );
};

export default Solutions;
